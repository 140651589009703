export default [
  {
    path: "/proxy",
    component: () => import(/* webpackChunkName: "proxy" */ "@/views/global/Clear"),
    meta: { layout: "auth" },
    children: [
      {
        path: "mercadopago",
        name: "proxy.mercadopago",
        component: () => import(/* webpackChunkName: "proxy" */ "@/views/proxy/MercadoPago"),
      },
      {
        path: "courtesy",
        name: "proxy.courtesy",
        component: () => import(/* webpackChunkName: "proxy" */ "@/views/proxy/Courtesy"),
      },
      {
        path: "*",
        redirect: "/",
      },
    ],
  },
];
