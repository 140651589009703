export default [
  {
    path: "/embed",
    component: () => import(/* webpackChunkName: "embed" */ "@/views/global/Index"),
    meta: { layout: "auth" },
    children: [
      // {
      //   path: ":orgSlug/:partyId/tickets",
      //   name: "embed.ticket",
      //   meta: { layout: "Blank" },
      //   component: () => import("@/views/proxy/TicketVerify"),
      // },
      {
        path: ":orgSlug/:partyId/tables",
        name: "embed.tables",
        meta: { layout: "Blank" },
        component: () => import(/* webpackChunkName: "embed" */ "@/views/embed/EmbedTableMap"),
      },
      {
        path: "*",
        redirect: "/",
      },
    ],
  }
];
