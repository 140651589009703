import authenticate from "@/auth/authenticate";

export default [
  {
    path: "/staff",
    component: () =>
      import(/* webpackChunkName: "staff" */ "@/views/global/Index"),

    children: [
      {
        path: "",
        name: "stuff.index",
        redirect: "/app/ticket",
      },
      {
        path: "scanner",
        name: "staff.scanner",
        meta: { layout: "Blank" },
        component: () =>
          import(
            /* webpackChunkName: "staff.scanner" */ "@/views/staff/scanner/ScannerInit"
          ),
      },
      {
        path: "scanner/:session",
        name: "staff.scanner.session",
        meta: { layout: "Blank" },
        component: () =>
          import(
            /* webpackChunkName: "staff.scanner" */ "@/views/staff/scanner/ScannerSession"
          ),
      },
      {
        path: "courtesy/:partyId",
        name: "staff.courtesyEmitter.party",
        meta: { layout: "Blank" },
        beforeEnter: authenticate,

        component: () =>
          import(
            /* webpackChunkName: "staff" */ "@/views/staff/CourtesyEmitterResponsible.vue"
          ),
      },
      {
        path: "*",
        redirect: "/app",
      },
    ],
  },
];
