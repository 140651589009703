import authenticate from "@/auth/authenticate";

export default [
  {
    path: "/auth",
    component: () => import(/* webpackChunkName: "auth" */ "@/views/global/Clear"),
    meta: { layout: "auth" },

    children: [
      {
        path: "",
        name: "sign-in",
        component: () => import(/* webpackChunkName: "auth" */ "@/views/app/sessions/SignIn"),
      },
      {
        path: "forgot-password",
        name: "forgot-password",
        component: () => import(/* webpackChunkName: "auth" */ "@/views/app/sessions/ForgotPassword"),
      },
      {
        path: "reset-password",
        name: "reset-password",
        component: () => import(/* webpackChunkName: "auth" */ "@/views/app/sessions/ResetPassword"),
      },
      {
        path: "register",
        name: "sign-up",
        component: () => import(/* webpackChunkName: "auth" */ "@/views/app/sessions/SignUp"),
      },
      {
        path: "account/webauthn",
        name: "webauthn.create",
        beforeEnter: authenticate,
        component: () => import(/* webpackChunkName: "auth" */ "@/views/app/sessions/WebAuthnRegister.vue"),
      },
      {
        path: "account/face/capture",
        name: "face.capture",
        beforeEnter: authenticate,
        component: () => import(/* webpackChunkName: "auth.face" */ "@/views/app/account/face/Capture.vue"),
      },
      {
        path: "account/face",
        name: "face.details",
        beforeEnter: authenticate,
        component: () => import(/* webpackChunkName: "auth.face" */ "@/views/app/account/face/Validate.vue"),
      },
      {
        path: "*",
        redirect: "/app",
      },
    ],
  },
];
